@use '../../styles/local-styles.scss' as local;
@use '../../styles/local-includes.scss' as includes;

.calendar-popup {
  background: white;
  padding: 14px 0 12px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  @media screen and (min-width: includes.$tablet-sm) {
    width: 550px;
  }
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 12px;
}

.calendar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding-left: 16px;
  padding-right: 16px;
  @media screen and (min-width: includes.$tablet-sm) {
    flex-direction: row;
    align-items: start;
    height: 329px;
  }

  .custom-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
  }

  .calendar-label {
    font-weight: bold;
    margin-right: 2px;
  }

  .react-datepicker {
    font-family: 'Inter';
    border: none;
    box-shadow: none;
  }

  .react-datepicker__header {
    background: white;
    border-bottom: none;
  }

  .react-datepicker__day {
    margin: 0;
    font-size: 14px;
    width: 34px;
    height: 34px;
  }

  .react-datepicker__day--in-selecting-range {
    border-radius: 0;
    background: local.$lightgrey;
    color: black;
  }

  .react-datepicker__day--in-range {
    background: #f3f4f6;
    color: #111827;
    border-radius: 0;
  }

  .react-datepicker__day:hover {
    background-color: local.$darkgrey;
    border-radius: 4px;
    color: white;
  }

  .react-datepicker__day.react-datepicker__day--selected {
    background-color: local.$blue;
    border-radius: 4px;
    color: white;
  }

  .prev,
  .next {
    // top: 10px;
    border: 1px solid local.$lightgrey;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .next:disabled,
  .prev:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .react-datepicker__month-container {
    border: 1px solid local.$lightgrey;
    border-radius: 6px;
  }

  .react-datepicker__day-name {
    color: local.$mediumgrey;
    font-size: 12px;
  }
}

.calendar-actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 12px;
  border-top: 1px solid local.$lightgrey;
  padding: 13px 16px 0;
}

.button {
  padding: 6px 13px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 6px;
  cursor: pointer;
  color: black;
}

.cancel-button {
  background: transparent;
  border: 1px solid local.$lightgrey;
}

.apply-button {
  background: local.$blue;
  color: white;
  border: none;
}
