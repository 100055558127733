@use '../../../../styles/local-includes.scss' as includes;

.pop-up-shop-hubspot-survey {
  background: rgba(255, 255, 255, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
}

.pop-up-shop-hubspot-survey__content {
  font-family: 'Inter';
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  background: white;
  border-radius: 16px;
  padding: 20px;
  margin: 20px;
  max-width: 600px;
  max-height: 400px;
  overflow: scroll;
  @media screen and (min-width: includes.$tablet-sm) {
    max-width: 600px;
    max-height: 400px;
  }
}

.pop-up-shop-hubspot-survey__ignore {
  margin-top: 10px !important;
  text-transform: none !important;
}

.pop-up-shop-hubspot-survey__content .hs-form-field > label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}

.pop-up-shop-hubspot-survey__content .hs-form-required {
  color: red;
}

.pop-up-shop-hubspot-survey__content .hs-form-field > .input {
  margin-bottom: 20px;
  font-size: 14px;
}

.pop-up-shop-hubspot-survey__content .hs-form-checkbox-display,
.pop-up-shop-hubspot-survey__content .hs-form-radio-display {
  align-items: center;
  display: flex;
  gap: 10px;
  margin-bottom: 5px;
}

.pop-up-shop-hubspot-survey__content .hs-fieldtype-textarea {
  border-radius: 3px;
  border-color: #d2d2d2;
  min-height: 80px;
  width: 100%;
}

.hs-button.primary {
  background-color: #1954eb;
  border-color: #1954eb;
  padding: 16px;
  color: white;
  font-weight: 600;
  border-radius: 6px;
}
