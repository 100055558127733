@import '../../../styles/local-includes.scss';

.samples__footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
  position: fixed;
  width: 100%;
  height: 63px;
  bottom: 0;
  left: 0;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: $black;
  background: $white;
  box-shadow: 30px -4px 40px rgba(0, 0, 0, 0.19),
    0px 1.12694px 5.00862px rgba(0, 0, 0, 0.095);
  z-index: 40;

  .samples__footer__mobile__checkout {
    @include breakpoint($mobile-lg) {
      width: 250px;
    }
    &.is-disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }

  .samples__footer__item__count {
    padding-right: 2em;
    @include breakpoint($mobile-lg) {
      width: 200px;
    }
  }

  .samples__footer__checkout__btn {
    background: $blue;
    color: $white;
    border: none;
    font-size: 16px;
    height: 42px;
    &.is-disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }
}
