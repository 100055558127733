@import '../../../styles/local-includes.scss';

.samples__summary__aside {
  padding: 0 !important;
}

.order__summary__container {
  padding: 25px;
}

.order__summary__banner {
  background: #edeff3;
  width: 100%;

  p {
    padding: 20px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $black;
  }
}

.order__summary__header {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}

.order__summary__empty {
  line-height: 24px;
  opacity: 0.6;
}
